<template>
  <div>
    <base-profile />
    <div class="container-profile pb-5">
      <profile-mi-credito class="container-profile-page" />
    </div>
  </div>
</template>
<script>
import BaseProfile from '@/components/Profile/BaseProfile'
import ProfileMiCredito from '@/components/Profile/ProfileMiCredito'
export default {
  components: { BaseProfile, ProfileMiCredito },
  mounted() {
    document.title = 'My credito | DEREK Tienda Online'
    // this.$gtag.pageview(this.$route.fullPath)
  },
}
</script>
