<template>
  <div>
    <pay-quota class="profile-mi-credito" />
  </div>
</template>
<script>
import PayQuota from '@/views/PayQuota'
export default {
  components: { PayQuota },
}
</script>
